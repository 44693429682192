/* ! Importing Google Font Poppins & Roboto */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', Arial, Helvetica, sans-serif, sans-serif;
  /* font-family: 'Roboto', sans-serif; */
}

body {
  scroll-behavior: smooth;
}

.pagination-item a {
  cursor: pointer;
  transition: all 0.5s ease-out;
}

.pagination-item a:hover {
  transform: scale(1.3);
}

.text-justify {
  text-align: justify;
}

.top-btn {
  background-color: #3f71b9;
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: 50px;
  width: 50px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-out;
  display: none;
}

.top-btn:hover {
  transform: scale(1.4);
}

.top-btn-icon {
  height: 20px;
  width: 20px;
}
.card-header-Image {
  border-radius: 5px;
}

.footer-box {
  background-color: #f8f9fa; /* Replace with your desired background color */
  padding: 10px;
  width: 100px; /* Ensure equal width */
  border-radius: 5px; /* Optional: Add some rounding to the corners */
}

.footer-box-icon {
  width: 30px; /* Set the width for the icons */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 5px; /* Space between the icon and the text (if needed) */
}

.primary_background_color {
  background-color: #007bff; /* Replace with your desired primary background color */
}

.primary_background_color {
  background-color: #3f71b9 !important;
}

.primary_text_color {
  color: #3f71b9 !important;
  /* text-decoration: underline; */
}
/* .primary_text_color:hover {
  text-decoration: underline;
} */

.links_hover:hover {
  text-decoration: underline;
}

.background-gray {
  background-color: gray;
}

.background-orange {
  background-color: orange;
}

.background-whitesmoke {
  background-color: whitesmoke !important;
}

.background-green {
  background-color: #5CB85C;
}

.background-dark {
  background-color: rgb(77, 77, 77) !important;
}

.background-dark-solid {
  background-color: #262626;
}

.background-transparent {
  background-color: rgba(0, 0, 0, 0.64) !important;
}

.text-gray {
  color: gray !important;
}

.text-light-grey {
  color: lightgrey !important;
}

.text-orange {
  color: orange;
}

.text-green {
  color: #5CB85C;
}

.text-red {
  color: red;
}

.links {
  transition: all 0.5s ease-out;
  cursor: pointer;
}

.links:hover {
  /* color: #3f71b9; */
  text-decoration: underline;
}

.text-line {
  text-decoration: 1px line-through solid black;
}

.page-links {
  transition: all 0.5s ease-out;
  cursor: pointer;
}

/* .page-links:hover {
  color: #3f71b9 !important;
  text-decoration: underline;
} */

.btn-hover-effect {
  transition: all 0.5s ease-out;
}

.btn-hover-effect:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}